<template>
  <div ref="localStream" style="display: none"></div>
</template>

<script>
import flashphonerMixin from "@/components/common/broadcast/mixins/flashphonerMixin";
import { RESOLUTIONS } from "@/constants/broadcast/broadcast-const";
import { nanoid } from "nanoid";

const RECORD_RESOLUTIONS = "1080p";
const RECORD_BITRATE = {
  minBitrate: 4500,
  maxBitrate: 5000,
};

export default {
  name: "BroadcastScreenRecorder",
  mixins: [flashphonerMixin],
  props: {
    start: {
      type: Boolean,
      default: false,
    },
    urlServer: {
      type: String,
      default: "",
    },
    room: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      session: null,
      stream: null,
      recordName: "",
    };
  },
  computed: {
    recordStreamName() {
      const isoDate = new Date().toISOString();
      return `${this.room}_eventRecord_${isoDate}_${nanoid(16)}`;
    },
    serverDomain() {
      return this.urlServer.replace("wss://", "");
    },
  },
  watch: {
    start(val) {
      if (val) {
        this.init();
      } else {
        this.stream?.stop();
      }
    },
  },
  async created() {
    await this.initFlashphoner();
  },
  methods: {
    init() {
      this.session = this.$FP
        .createSession({ urlServer: this.urlServer })
        .on(this.SESSION_STATUS.ESTABLISHED, () => {
          setTimeout(() => {
            this.startRecord();
          }, 2000);
        })
        .on(this.SESSION_STATUS.DISCONNECTED, () => {
          this.session = null;
        })
        .on(this.SESSION_STATUS.FAILED, () => {
          this.showNotification({
            title: this.$t("broadcast.errors.connectionFailed"),
          });
        });
    },
    startRecord() {
      this.session
        .createStream({
          name: this.recordStreamName,
          display: this.$refs.localStream,
          record: true,
          constraints: {
            audio: false,
            video: {
              width: RESOLUTIONS[RECORD_RESOLUTIONS].width,
              height: RESOLUTIONS[RECORD_RESOLUTIONS].height,
              type: "screen",
              frameRate: 30,
              mediaSource: "screen",
              withoutExtension: true,
              ...RECORD_BITRATE,
            },
          },
        })
        .on(this.STREAM_STATUS.PUBLISHING, stream => {
          this.stream = stream;
          const el = document.getElementById(stream.id());
          el.srcObject.getVideoTracks()[0].addEventListener("ended", stream.stop);
          this.showNotification(
            {
              title: this.$t("broadcast.recordEventTitle"),
            },
            "info",
          );
        })
        .on(this.STREAM_STATUS.UNPUBLISHED, stream => {
          this.recordName = stream.getRecordInfo();
          this.stop();
        })
        .on(this.STREAM_STATUS.FAILED, () => {
          this.stop();
          this.showNotification(
            {
              title: this.$t("broadcast.recordEventErrorTitle"),
            },
            "error",
          );
        })
        .publish();
    },
    stop() {
      this.stream = null;
      this.session.disconnect();
      this.showNotification(
        {
          title: this.$t("broadcast.recordEventEndTitle"),
        },
        "info",
      );
      this.$emit("stop", this.recordName);
    },
    showNotification(
      message = {
        title: "",
        text: "",
      },
      type = "success",
    ) {
      this.$notify({
        group: "recording",
        type,
        duration: 5000,
        ...message,
      });
    },
  },
};
</script>
